<template>
  <div id="data-download">
    <!--模拟的标题-->
    <div class="header">
      <el-input placeholder="请输入内容" v-model="searchValue" clearable @clear="handleClear">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
        <el-button class="search-btn" slot="append" @click="handleSearch">搜索</el-button>
      </el-input>
    </div>
    <!--滑动区域-->
    <mescroll-vue
      v-loading="loading"
      ref="mescroll"
      :down="mescrollDown"
      :up="mescrollUp"
      @init="mescrollInit"
    >
      <!--展示上拉加载的数据列表-->
      <ul id="dataList" class="data-list">
        <li
          class="article-item"
          v-for="article in dataList"
          :key="article.id"
          @click="handleGoDetail(article)"
        >
          <div class="article-title">{{ article.title.rendered }}</div>
          <div class="article-info">
            <div class="article-content">{{ unescape(article.content.rendered) }}</div>
            <el-image
              style="width: 150px; height: 70px"
              :src="article.featured_media_url"
              fit="scale-down"
            >
            </el-image>
          </div>
        </li>
      </ul>
    </mescroll-vue>
  </div>
</template>

<script>
// 引入mescroll的vue组件
import MescrollVue from "mescroll.js/mescroll.vue";

const axios = require("axios");
const myRequest = axios.create({
  baseURL: "/api",
});
export default {
  name: "datadownload",
  components: {
    MescrollVue,
  },
  data() {
    return {
      loading: false,
      pageTotal: 0,
      pageNum: 1,
      searchValue: "",
      dataList: [], // 列表数据
      mescroll: null, // mescroll实例对象
      mescrollDown: {}, //下拉刷新的配置. (如果下拉刷新和上拉加载处理的逻辑是一样的,则mescrollDown可不用写了)
      mescrollUp: {
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 5, // 每页数据的数量
        },
        noMoreSize: 1, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        toTop: {
          // 配置回到顶部按钮
          src: "./static/mescroll/mescroll-totop.png",
        },
        lazyLoad: {
          use: true, // 是否开启懒加载,默认false
        },
        empty: {
          //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
          warpId: "dataList", //父布局的id (1.3.5版本支持传入dom元素)
          icon: "./static/mescroll/mescroll-empty.png", //图标,默认null,支持网络图
          tip: "暂无相关数据~", //提示
        },
      },
    };
  },
  methods: {
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    getArticle() {
      return myRequest
        .get(
          `/wp-json/wp/v2/download?downloads_category=59&page=${this.pageNum}&per_page=5&search=${this.searchValue}`
        )
        .then((result) => {
          const { data, headers } = result;
          this.pageTotal = headers["x-wp-total"];
          // 处理成功情况
          return Promise.resolve(data ?? []);
        })
        .catch((error) => {
          // 处理错误情况
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    // 上拉回调
    upCallback(page) {
      // 模拟联网
      this.getListDataFromNet(page.num, page.size)
        .then(({ arr }) => {
          // 如果是第一页需手动制空列表
          console.log("page.num", page.num);
          if (page.num === 1) this.dataList = [];
          // 把请求到的数据添加到列表
          this.dataList = this.dataList.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endBySize(arr.length, this.pageTotal);
          });
        })
        .catch(() => {
          this.mescroll.endErr();
        });
    },
    getListDataFromNet(pageNum, pageSize) {
      this.pageNum = pageNum;
      return new Promise(async (resolve, reject) => {
        const mokeData = await this.getArticle();
        resolve({ arr: mokeData });
      });
    },
    handleGoDetail(article) {
      this.$store.commit("changeActiveArticleInfo", article);
      window.localStorage.setItem("article", JSON.stringify(article));
      this.$router.push("/detail");
    },
    unescape(html) {
      let s = "";
      if (html == undefined) return "";
      s = html.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      s = s.replace(/<br\/>/g, "\n");
      s = s.replace(/&ldquo;/g, '"');
      s = s.replace(/&rdquo;/g, '"');
      s = s.replace(/&rarr;/g, "→");
      s = s.replace(/<\/?[^>]*>/g, ""); //过滤标签
      return s;
    },
    handleSearch() {
      this.loading = true;
      this.upCallback({ num: 1, size: 5 });
    },
    handleClear() {
      this.loading = true;
      this.upCallback({ num: 1, size: 5 });
    },
  },
  activated() {
    this.$nextTick(() => {
      this.mescroll.scrollTo(this.$refs.mescroll.lastScrollTop, 0);
    });
  },
  beforeRouteEnter(to, from, next) {
    // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next((vm) => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.mescroll && vm.$refs.mescroll.beforeRouteEnter();
    });
  },
  beforeRouteLeave(to, from, next) {
    // 如果没有配置顶部按钮或isBounce,则beforeRouteLeave不用写
    // 记录列表滚动的位置,隐藏顶部按钮和isBounce的配置
    this.$refs.mescroll && this.$refs.mescroll.beforeRouteLeave();
    next();
  },
};
</script>

<style lang="less" scoped>
.mescroll {
  position: fixed;
  top: 44px + 22px;
  bottom: 0;
  height: auto;
}

.header {
  z-index: 9990;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding: 12px 10px;
  text-align: center;
  border-bottom: 1px solid #eee;
  background-color: white;
}
.article-item {
  margin: 15px 10px;
  font-size: 16px;
  line-height: 30px;
  color: #444;
  border-bottom: 1px #d8d8d8 solid;

  .article-title {
    width: 100%;
    line-height: 20px;
    font-weight: 600;
    font-size: 20px;
    color: #333;
  }
  .article-info {
    display: flex;
    padding: 15px 0;

    .article-content {
      width: calc(100% - 60px);
      height: auto;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 14px;
      line-height: 23.33px;
      padding-right: 10px;
    }
  }
}

/deep/ .el-input__inner {
  background-color: #ededed;
  border: none;
}
/deep/ .el-input-group__append {
  color: #000;
  background-color: #fff;
  border: none;
  padding-left: 10px;
}

/deep/ .el-input-group--append .el-input__inner,
.el-input-group__prepend {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
