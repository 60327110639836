import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    activeArticleInfo: {}
  },
  mutations: {
    changeActiveArticleInfo(state, article) {
      state.activeArticleInfo = article
    }
  },
  actions: {
    //当页面刷新时，重新给vuex赋值
    loadLocalArticle({ commit }) {
      const articleInfo = JSON.parse(window.localStorage.getItem('article'))
      if (articleInfo) commit('changeActiveArticleInfo', articleInfo)
    }
  }
})

export function setupStore() {
  store.dispatch('loadLocalArticle')
}

export default store