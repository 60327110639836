<template>
  <div class="detail">
    <div class="article">
      <div class="article-title">{{ activeArticleInfo.title.rendered }}</div>
      <div class="article-info">
        <el-avatar shape="square" size="medium" src="./static/icon/icon.png"></el-avatar>
        <span style="padding-left: 20px; color: #666666">{{ formatTime }}</span>
      </div>
      <div
        class="article-content"
        v-html="handleHtmlImage(activeArticleInfo.content.rendered)"
      ></div>
      <div style="height: 45px"></div>
    </div>
    <div class="bottom-btn">
      <el-button
        class="bottom-item download-btn"
        type="warning"
        icon="el-icon-mydownload"
        @click="handleDownload"
      >
        下载
      </el-button>
      <el-button class="bottom-item" type="primary" icon="el-icon-back" @click="handleGoHome"
        >返回</el-button
      >
    </div>
  </div>
</template>

<script>
import OSS from "ali-oss";
import { formatUtcString } from "../utils/date-format.js";
export default {
  data() {
    return {
      client: null,
    };
  },
  computed: {
    formatTime: function () {
      return formatUtcString(this.activeArticleInfo.date);
    },
    activeArticleInfo: function () {
      return this.$store.state.activeArticleInfo;
    },
  },
  mounted: function () {
    this.client = new OSS({
      region: "oss-cn-beijing", //这些都是你们公司在购买阿里云的时候设定的
      accessKeyId: "LTAI4G7Rjxwdd2FUHhJrbSV2", //这些都是你们公司在购买阿里云的时候设定的
      accessKeySecret: "nA9HOoAQ4BGOGtfK4q85p4SdrhynQc", //这些都是你们公司在购买阿里云的时候设定的
      bucket: "doc-orcadpcb",
      secure: true, //在 OSSClient 初始化时加上 secure:true 就是 https 传输了
    });
  },
  methods: {
    handleGoHome() {
      this.$router.back();
    },
    handleDownload() {
      if (!this.activeArticleInfo.acf.url) {
        this.$message.warning("暂无相关文件！");
        var elArr = document.querySelectorAll(".el-message");
        if (elArr.length) {
          for (const el of elArr) {
            el.style.cssText = "min-width: 0;";
          }
        }
        return;
      }
      // 注意这个filename就是下载文件以后要显示的文件名，千万不要写错了，要不然下载下来的文件名会是乱码
      const filePath = this.activeArticleInfo.acf.url.split(".com")[1];
      console.log(filePath);
      // const deURL = decodeURI(filePath);
      const deURL = decodeURI(filePath);
      console.log(deURL);
      const splitUrlArr = deURL.split("/");
      const fileName = splitUrlArr[splitUrlArr.length - 1];
      console.log(fileName);
      const response = {
        // "content-disposition": `attachment; filename=${encodeURIComponent(fileName)}`,
        "content-disposition":
          "attachment;filename*=UTF-8''" + encodeURIComponent(fileName, "UTF-8"),
        // "content-type": "application/octet-stream",
      };
      // 注意一下这个filePath，是上传的时候返回来的链接的全路径，要包含文件名和文件后缀
      const url = this.client.signatureUrl(deURL, { response });
      // 此时这个url就是一个可以下载的链接，可以直接在浏览器打开下载，也可以通过前端的a标签实现下载
      this.downloadUseA(url, fileName);
    },
    downloadUseA(url, name) {
      const a = document.createElement("a");
      a.download = decodeURIComponent(name);
      a.href = url;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    handleHtmlImage(html) {
      const newHtml = html
        .replace(/<img([\s\w"-=\/\.:;]+)((?:(height="[^"]+")))/gi, "<img$1")
        .replace(/<img([\s\w"-=\/\.:;]+)((?:(width="[^"]+")))/gi, "<img$1")
        .replace(/<img([\s\w"-=\/\.:;]+)((?:(style="[^"]+")))/gi, "<img$1")
        .replace(/<img([\s\w"-=\/\.:;]+)((?:(alt="[^"]+")))/gi, "<img$1")
        .replace(/<img([\s\w"-=\/\.:;]+)/gi, '<img style="width: 100%;" $1');
      return newHtml;
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  line-height: 2;
  font-size: 16px;
  color: #333;
  .article {
    padding: 15px 10px;
    .article-title {
      width: 100%;
      line-height: 20px;
      font-weight: 600;
      font-size: 20px;
    }

    .article-info {
      padding: 15px 0;
      display: flex;
      align-items: center;
    }
  }

  .bottom-btn {
    width: 100%;
    height: 7%;
    position: fixed;
    bottom: 0;
    display: flex;
    z-index: 999;

    .bottom-item {
      flex: 1;
      height: 100%;
      border-radius: 0;
      font-size: 18px;
    }
    .el-button + .el-button {
      margin-left: 0 !important;
    }
  }
}
/deep/ li:before {
  content: "";
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

/deep/ .el-icon-mydownload {
  background: url("../../public/static/icon/download.svg") center no-repeat; // 注意此处的url，在这里引入自己的图片
  font-size: 16px;
  background-size: cover;
}

/deep/ .el-icon-mydownload:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
</style>
